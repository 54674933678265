<template>
  <div class="flex flex-col min-h-screen bg-gradient-to-b from-pink-50/80 to-white/90">
    <main class="flex-1">
      <div class="max-w-4xl mx-auto px-4">
        <!-- 语言切换按钮 - 添加在标题上方 -->
        <!-- <div class="flex justify-end pt-4">
          <button 
            @click="toggleLanguage" 
            class="px-4 py-1.5 rounded-full text-sm font-medium 
                   bg-white text-pink-500 border border-pink-200 
                   hover:bg-pink-50 hover:border-pink-300 
                   transition-colors duration-200
                   flex items-center space-x-1">
            <LanguageIcon class="w-4 h-4" />
            <span>{{ currentLang === 'zh-CN' ? 'English' : '中文' }}</span>
          </button>
        </div> -->

        <!-- 装饰性标题 -->
        <div class="mt-4 mb-2 text-center relative flex items-center justify-center space-x-6">
          <!-- 左侧装饰 -->
          <div class="flex items-center">
            <div class="h-px w-16 bg-gradient-to-r from-transparent to-pink-300"></div>
            <div class="w-1.5 h-1.5 rounded-full bg-pink-400 mx-2"></div>
            <StarIcon class="w-5 h-5 text-pink-400" />
          </div>

          <!-- 标题文字 -->
          <h1 class="text-2xl font-medium text-pink-400 flex-shrink-0">{{ t('addressBook.title') }}</h1>

          <!-- 右侧装饰 -->
          <div class="flex items-center">
            <StarIcon class="w-5 h-5 text-pink-400" />
            <div class="w-1.5 h-1.5 rounded-full bg-pink-400 mx-2"></div>
            <div class="h-px w-16 bg-gradient-to-l from-transparent to-pink-300"></div>
          </div>
        </div>

        <!-- 好友列表 -->
        <div class="grid grid-cols-1 gap-4 py-4">
          <div v-for="friend in friends" 
               :key="friend.friend_id" 
               @click="goToChat(friend.friend_id)"
               class="bg-white rounded-xl p-4 shadow-sm border border-pink-100 
                      hover:border-pink-200 transition-all cursor-pointer">
            <div class="flex items-center space-x-4">
              <!-- 头像 -->
              <div class="relative">
                <img :src="friend.avatar_url" 
                     :alt="friend.name"
                     class="w-16 h-16 rounded-full object-cover border-2 border-pink-100">
                <div class="absolute -bottom-1 -right-1 w-4 h-4 bg-green-400 
                            rounded-full border-2 border-white"></div>
              </div>

              <!-- 信息 -->
              <div class="flex-1">
                <h2 class="text-lg font-medium text-gray-800">{{ friend.name }}</h2>
                <p class="text-sm text-gray-600 mt-1 line-clamp-2 
                         overflow-hidden display-webkit-box webkit-line-clamp-2 
                         webkit-box-orient-vertical">{{ friend.bio }}</p>
              </div>

              <!-- 箭头 -->
              <ChevronRightIcon class="h-6 w-6 text-gray-400" />
            </div>
          </div>
        </div>

        <!-- 加载状态 -->
        <div v-if="loading" class="flex justify-center py-8">
          <div class="animate-spin rounded-full h-8 w-8 border-b-2 border-pink-400"></div>
        </div>

        <!-- 错误提示 -->
        <div v-if="error" 
             class="text-center py-8 text-red-500">
          {{ t('addressBook.error') }}
        </div>

        <!-- 空状态 -->
        <div v-if="!loading && friends.length === 0 && !error" 
             class="text-center py-8 text-gray-500">
          {{ t('addressBook.empty') }}
        </div>

        <div class="text-center text-xs text-gray-400 mt-3">
          v0.3.2
        </div>
      </div>
    </main>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { 
  StarIcon, 
  ChevronRightIcon,
  // LanguageIcon // 新增语言图标
} from '@heroicons/vue/24/outline'
import { useLanguage } from '../hooks/useLanguage'

const { t, currentLang, changeLang } = useLanguage()
const router = useRouter()
const friends = ref([])
const loading = ref(false)
const error = ref('')

const getFriendsList = async () => {
  loading.value = true
  error.value = ''

  try {
    const response = await fetch('/api/friend/list')
    if (!response.ok) {
      throw new Error(t('addressBook.fetchError'))
    }
    const data = await response.json()
    friends.value = data
  } catch (err) {
    console.error('Error fetching friends list:', err)
    error.value = t('addressBook.error')
  } finally {
    loading.value = false
  }
}

const goToChat = (friendId) => {
  router.push({
    name: 'ChatView',
    query: { friend: friendId }
  })
}

// 切换语言
// const toggleLanguage = () => {
//   changeLang(currentLang.value === 'zh-CN' ? 'en-US' : 'zh-CN')
// }

onMounted(() => {
  getFriendsList()
})
</script>