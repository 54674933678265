import { createRouter, createWebHistory } from 'vue-router'
import FriendList from '../views/FriendList.vue'
import ChatView from '../views/ChatView.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: FriendList,
    meta: {
      title: '通讯薄'
    }
  },
  {
    path: '/chat',
    name: 'ChatView',
    component: ChatView,
    meta: {
      title: '聊天'
    },
    beforeEnter: (to, from, next) => {
      const friendId = to.query.friend
      if (!friendId) {
        next({ name: 'Home' })
      } else {
        next()
      }
    }
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/'
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title ? `${to.meta.title} - 通讯薄` : '通讯薄'
  next()
})

export default router