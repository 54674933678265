<template>
  <div class="min-h-screen bg-hearts">
    <router-view></router-view>
  </div>
</template>

<script setup>
import { onMounted } from 'vue'

// 用户初始化
const userInit = async () => {
  try {
    // 1. 先获取现有的userId
    let userId = localStorage.getItem('user_id')

    // 2. 检查URL中的uid参数
    const urlParams = new URLSearchParams(window.location.search)
    const urlUserId = urlParams.get('uid')

    if (urlUserId) {
      // 如果URL中有uid且与localStorage中的不同，才更新
      if (userId !== urlUserId) {
        userId = urlUserId
        localStorage.setItem('user_id', urlUserId)
      }
      // 清理URL参数
      const newUrl = new URL(window.location.href)
      newUrl.searchParams.delete('uid')
      window.history.replaceState({}, document.title, newUrl)
    }

    // 3. 如果没有userId，创建新用户
    if (!userId) {
      const response = await fetch('/api/user/create', {
        method: 'POST',
      })
      if (!response.ok) {
        throw new Error('Failed to create user')
      }
      const data = await response.json()
      if (data.user_id) {
        userId = data.user_id
        localStorage.setItem('user_id', userId)
      } else {
        throw new Error('No user_id in response')
      }
    }

  } catch (error) {
    console.error('Error in userInit:', error)
    // 只在控制台输出错误，不影响应用使用
  }
}

// 组件挂载时进行用户初始化
onMounted(() => {
  userInit()
})
</script>


<style>
.custom-scrollbar::-webkit-scrollbar {
  width: 6px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: transparent;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(156, 163, 175, 0.5);
  border-radius: 20px;
  border: transparent;
}

.message-bubble-in {
  animation: slideIn 0.3s ease-out;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.bg-hearts {
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z' fill='rgba(252, 165, 165, 0.1)'/%3E%3C/svg%3E");
  background-repeat: repeat;
}

.profile-image {
  animation: float 6s ease-in-out infinite;
}

@keyframes float {
  0% {
    transform: translateY(0px);
  }

  50% {
    transform: translateY(-10px);
  }

  100% {
    transform: translateY(0px);
  }
}

.header-transition {
  transition: all 0.3s ease-in-out;
}

.hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}
</style>