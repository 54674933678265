export default {
    addressBook: {
        title: 'Address Book',
        loading: 'Loading...',
        empty: 'No friends yet',
        error: 'Failed to get friends list, please try again later',
        fetchError: 'Failed to fetch friends list'
    },
    friend: {
        online: 'Online',
        offline: 'Offline',
        bio: 'Bio'
    }
}