export default {
    addressBook: {
      title: '通讯薄',
      loading: '加载中...',
      empty: '暂无好友',
      error: '获取好友列表失败，请稍后重试',
      fetchError: '获取好友列表失败'
    },
    friend: {
      online: '在线',
      offline: '离线',
      bio: '个人简介'
    }
  }