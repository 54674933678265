<template>
  <div class="flex flex-col h-screen bg-gradient-to-b from-pink-50/80 to-white/90">
    <!-- 固定头部 -->
    <div v-show="isScrolled"
      class="fixed top-0 left-0 right-0 bg-white/95 backdrop-blur-sm shadow-md z-50 header-transition">
      <div class="max-w-4xl mx-auto px-4 py-2 relative">
        <!-- 返回按钮固定在左侧 -->
        <button @click="goBack"
          class="absolute left-4 top-1/2 -translate-y-1/2 p-1.5 rounded-full hover:bg-gray-100 transition-colors">
          <ChevronLeftIcon class="h-5 w-5 text-gray-600" />
        </button>
        <!-- 头像和名字居中显示 -->
        <div class="flex justify-center">
          <div class="flex items-center space-x-3">
            <img :src="friendInfo.avatar_url" :alt="friendInfo.name"
              class="w-10 h-10 rounded-full object-cover border-2 border-pink-100">
            <h2 class="text-lg font-semibold text-gray-800">{{ friendInfo.name }}</h2>
          </div>
        </div>
      </div>
    </div>

    <!-- 主内容区域（可滚动） -->
    <div class="flex-1 overflow-y-auto custom-scrollbar" ref="mainContainer" @scroll="handleScroll">
      <div class="flex flex-col max-w-4xl mx-auto px-4">
        <!-- 头像和介绍卡片 -->
        <div class="p-8 pb-6 relative">
          <div class="flex flex-col items-center space-y-4">
            <!-- 头像容器 -->
            <div class="relative inline-block profile-image">
              <div class="relative">
                <img :src="friendInfo.avatar_url" :alt="friendInfo.name"
                  class="w-24 h-24 rounded-full object-cover border-4 border-pink-100 shadow-md">
                <div class="absolute -bottom-1 -right-1 w-6 h-6 bg-green-400 rounded-full border-2 border-white"></div>
              </div>
            </div>
            <!-- 名字和介绍 -->
            <div class="text-center">
              <div class="flex items-center justify-center space-x-2 mb-2">
                <h1 class="text-xl font-semibold text-gray-800 mt-2">{{ friendInfo.name }}</h1>
                <button @click="showMemories = true"
                  class="p-1.5 rounded-full bg-pink-100 hover:bg-pink-200 transition-colors flex items-center mt-2"
                  title="查看记忆">
                  <ClipboardIcon class="h-4 w-4 text-pink-400" />
                </button>
              </div>
              <div
                class="text-sm mt-2 inline-block font-medium bg-gradient-to-r from-blue-400 via-purple-400 to-pink-400 bg-clip-text text-transparent hover:opacity-80 transition-opacity">
                {{ friendInfo.bio }}
              </div>
            </div>
          </div>
        </div>

        <!-- 聊天区域 -->
        <div class="w-full mb-28">
          <!-- 历史消息分隔线 -->
          <div v-if="messagesHistory" class="flex items-center space-x-3 p-4 mb-2">
            <div class="flex-grow border-t border-gray-150"></div>
            <div class="text-center italic text-gray-400 text-sm">最近消息</div>
            <div class="flex-grow border-t border-gray-150"></div>
          </div>

          <!-- 消息列表 -->
          <template v-for="(message, index) in messages" :key="index">
            <!-- 用户消息 -->
            <div v-if="message.role === 'user' && message.content.some(contentBlock => contentBlock.type === 'text')"
              class="flex justify-end mb-4 message-bubble-in">
              <div class="bg-pink-500 text-white rounded-2xl rounded-tr-sm py-2 px-4 shadow-md max-w-[80%]">
                <div v-for="(contentBlock, idx) in message.content" :key="idx">
                  <span v-if="contentBlock.type === 'text'" class="break-words">
                    {{ contentBlock.text }}
                  </span>
                </div>
              </div>
            </div>

            <!-- AI回复消息 -->
            <div v-else-if="message.role === 'assistant' && message.content.length > 0"
              class="flex flex-col justify-start mb-4 message-bubble-in">
              <div class="bg-white rounded-2xl rounded-tl-sm py-2 px-4 shadow-md max-w-[80%] border border-pink-100">
                <div v-for="(contentBlock, idx) in message.content" :key="idx">
                  <div v-if="contentBlock.type === 'text'" v-html="renderMarkdown(contentBlock.text)"
                    class="text-gray-800"></div>
                </div>
              </div>
            </div>

            <!-- 思考状态消息 -->
            <div v-else-if="message.role === 'think' && message.content.length > 0"
              class="flex flex-col justify-start mb-4 message-bubble-in">
              <div class="bg-white rounded-2xl rounded-tl-sm py-2 px-4 shadow-md max-w-[80%] border border-pink-100">
                <div class="text-sm text-pink-400 flex items-center italic">
                  💭 "{{ message.content[0].text }}"
                </div>
              </div>
            </div>
            <div v-else-if="message.role === 'error' && message.content.length > 0"
              class="flex justify-center items-center p-4 mb-2">
              <div class="bg-pink-50 text-pink-500 text-sm flex items-center space-x-1.5 px-4 py-2 rounded-lg">
                <InformationCircleIcon class="h-4 w-4" />
                <span>{{ message.content[0].text }}</span>
              </div>
            </div>

          </template>
        </div>


      </div>
    </div>

    <!-- 底部输入区域 -->
    <div class="fixed bottom-0 left-0 right-0 bg-white/80 backdrop-blur-md p-4 pb-3 flex flex-col justify-center">
      <!-- 输入框部分 -->
      <div class="flex flex-col bg-white rounded-[23px] p-2 shadow-lg max-w-4xl w-full border border-pink-100 mx-auto">
        <!-- 已选择图片显示区域 -->
        <div v-if="selectedImage" class="relative w-full flex items-center p-2">
          <div class="relative">
            <img :src="'data:' + selectedImage.media_type + ';base64,' + selectedImage.data" alt="Selected Image"
              class="w-14 h-14 object-cover rounded-lg" />
            <button @click="removeSelectedImage"
              class="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2 bg-red-400 text-white rounded-full w-5 h-5 flex items-center justify-center text-sm hover:bg-red-500 transition-colors duration-300">
              &times;
            </button>
          </div>
        </div>

        <!-- 输入区域和按钮 -->
        <div class="flex items-end">
          <textarea v-model="newMessage" rows="1"
            :placeholder="thinking ? `${friendInfo.name}正在思考...` : `和${friendInfo.name}说点什么吧...`" @input="autoResize"
            @keydown="handleKeydown" ref="messageInput"
            class="flex-1 p-2 border-none resize-none focus:outline-none overflow-auto min-h-[36px] max-h-[160px] bg-transparent custom-scrollbar">
      </textarea>

          <!-- 发送按钮 -->
          <div class="min-h-[38px] min-w-[38px] flex items-center justify-center">
            <button @click="sendMessage"
              :class="['p-2 rounded-full transition-all duration-300',
                (newMessage === '' && !selectedImage) ? 'bg-gray-200 cursor-not-allowed' : 'bg-pink-500 hover:bg-pink-600']">
              <PaperAirplaneIcon class="h-5 w-5"
                :class="(newMessage === '' && !selectedImage) ? 'text-gray-400' : 'text-white'" />
            </button>
          </div>
        </div>
      </div>

      <!-- 新增的QQ群号 -->
      <div class="text-center text-xs text-gray-400 mt-3">
        QQ群：884309612
      </div>
    </div>

    <!-- 记忆浮层 -->
    <MemoriesModal v-if="showMemories" :friend-info="friendInfo" @close="showMemories = false" />

  </div>
</template>

<script setup>
import { ref, onMounted, nextTick } from 'vue'
import { useRouter } from 'vue-router'
import { marked } from 'marked'
import {
  ChevronLeftIcon,
  PaperAirplaneIcon,
  ClipboardIcon,
  InformationCircleIcon
} from '@heroicons/vue/24/outline'
import MemoriesModal from '../components/memories/MemoriesListModal.vue'

// 路由
const router = useRouter()

// 状态定义
const isScrolled = ref(false)
const showMemories = ref(false)
const messages = ref([])
const messagesHistory = ref(false)
const newMessage = ref('')
const thinking = ref(false)
const selectedImage = ref(null)
const mainContainer = ref(null)
const messageInput = ref(null)

// 好友信息
const friendInfo = ref({
  friend_id: '',
  name: '',
  avatar_url: '',
  bio: '',
  region: ''
})

// 获取好友信息
const getFriendInfo = async () => {
  const urlParams = new URLSearchParams(window.location.search)
  const friendId = urlParams.get('friend')

  if (!friendId) {
    router.push('/')
    return
  }

  try {
    const response = await fetch(`/api/friend/get?friend_id=${friendId}`)
    if (response.ok) {
      friendInfo.value = await response.json()
      document.title = friendInfo.value.name
    }
  } catch (error) {
    console.error('Error fetching friend info:', error)
  }
}

// 获取消息历史
const messagesInit = async () => {
  const userId = localStorage.getItem('user_id')
  const urlParams = new URLSearchParams(window.location.search)
  const friendId = urlParams.get('friend')

  try {
    const response = await fetch(`/api/messages/get?user_id=${userId}&friend=${friendId}`)
    if (response.ok) {
      const messagesData = await response.json()
      if (messagesData.length > 0) {
        messagesHistory.value = true
        messages.value = messagesData.map(message => {
          if (typeof message.content === 'string') {
            try {
              message.content = JSON.parse(message.content)
            } catch (error) {
              console.log('Parse error:', error)
            }
          }
          return message
        }).reverse()

        await nextTick()
        setTimeout(() => {
          scrollToBottom()
        }, 100)
      }
    }
  } catch (error) {
    console.error('Error fetching messages:', error)
  }
}


// 发送消息
const sendMessage = async () => {
  const message = newMessage.value.trim()
  if (message === '' && !selectedImage.value) return
  if (thinking.value) return

  let messageContent = []

  if (selectedImage.value) {
    messageContent.push({
      type: 'image',
      source: {
        type: 'base64',
        media_type: selectedImage.value.media_type,
        data: selectedImage.value.data,
      },
    })
  }

  if (message !== '') {
    messageContent.push({
      type: 'text',
      text: message
    })
  }

  messages.value.push({
    role: 'user',
    content: messageContent
  })

  removeSelectedImage()
  autoResize()
  scrollToBottom()
  await aiModel()
}

// AI模型交互
const aiModel = async () => {

  thinking.value = true

  // 定义需要重置历史的角色类型
  const resetRoles = ['error']
  // 找到最后一条需要重置的消息的索引
  const lastResetIndex = [...messages.value].reverse().findIndex(msg => resetRoles.includes(msg.role))
  // 准备发送的消息数组
  const messagesToSend = lastResetIndex >= 0
    ? messages.value.slice(-(lastResetIndex + 1))
    : messages.value

  const requestBody = JSON.stringify({
    friendId: friendInfo.value.friend_id,
    userId: localStorage.getItem('user_id'),
    newMessage: newMessage.value,
    messages: messagesToSend
      .filter(msg => msg.role !== 'think')
      // .filter(msg => msg.role !== 'clear')
      .filter(msg => msg.role !== 'error')
      .slice(-10)
  })

  newMessage.value = ''

  try {
    const response = await fetch('/api/chat', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: requestBody,
    })

    if (!response.body) throw new Error('ReadableStream not available')

    const reader = response.body.getReader()
    const decoder = new TextDecoder('utf-8')
    let buffer = ''

    // eslint-disable-next-line no-constant-condition
    while (true) {
      const { done, value } = await reader.read()
      if (done) break

      buffer += decoder.decode(value, { stream: true })
      const chunks = buffer.split('\n\n')

      for (const chunk of chunks.slice(0, -1)) {
        const jsonData = JSON.parse(chunk.replace('data: ', '').trim())
        const { event, data } = jsonData

        switch (event) {
          case 'message_start':
            messages.value.push({
              role: 'assistant',
              content: [{
                type: 'text',
                text: '',
              }]
            })
            break

          case 'message_delta':
            // eslint-disable-next-line no-case-declarations
            const textBlock = messages.value[messages.value.length - 1].content.find(
              block => block.type === 'text'
            )
            if (textBlock) {
              textBlock.text += data.text
            }
            break

          case 'think':
            messages.value.push({
              role: 'think',
              content: [{
                type: 'text',
                text: data.content
              }]
            })
            break


          case 'error':
            messages.value.push({
              role: 'error',
              content: [{
                type: 'text',
                text: data.content
              }]
            })
            break

          // case 'clear':
          //   messages.value.push({
          //     role: 'clear',
          //     content: [{
          //       type: 'text',
          //       text: data.content
          //     }]
          //   })
          //   break

          case 'stop':
            thinking.value = false
            break
        }
        scrollToBottom()
      }

      buffer = chunks[chunks.length - 1]
    }
  } catch (error) {
    console.error('Error in aiModel:', error)
    thinking.value = false
  }
}

// UI 相关方法
const handleScroll = (e) => {
  isScrolled.value = e.target.scrollTop > 200
}

const autoResize = () => {
  nextTick(() => {
    const textarea = messageInput.value
    textarea.style.height = 'auto'
    textarea.style.height = `${textarea.scrollHeight}px`
  })
}

const scrollToBottom = () => {
  nextTick(() => {
    const container = mainContainer.value
    container.scrollTop = container.scrollHeight
  })
}

const goBack = () => {
  router.push('/')
}


// 键盘事件处理
const handleKeydown = (event) => {
  if (event.key === 'Enter' && !event.shiftKey) {
    event.preventDefault()
    sendMessage()
  }
}

// Markdown 渲染
const renderMarkdown = (content) => {
  return marked.parse(content)
}

// 图片处理方法
const handleImageUpload = (event) => {
  const file = event.target.files[0]
  if (file && file.type.startsWith('image/')) {
    const reader = new FileReader()
    reader.onload = (e) => {
      const arrayBuffer = e.target.result
      const uint8Array = new Uint8Array(arrayBuffer)
      const mediaType = detectImageMediaType(uint8Array)

      if (!mediaType) {
        alert("无效的文件类型！只允许 JPEG, PNG, GIF, 和 WebP。")
        return
      }

      const base64Reader = new FileReader()
      base64Reader.onload = (base64Event) => {
        selectedImage.value = {
          media_type: mediaType,
          data: base64Event.target.result.split(',')[1],
        }
      }
      base64Reader.readAsDataURL(file)
    }
    reader.readAsArrayBuffer(file)
  }
}

const detectImageMediaType = (buffer) => {
  // JPEG
  if (buffer[0] === 0xFF && buffer[1] === 0xD8 && buffer[2] === 0xFF) {
    return 'image/jpeg'
  }
  // PNG
  if (buffer[0] === 0x89 && buffer[1] === 0x50 && buffer[2] === 0x4E && buffer[3] === 0x47) {
    return 'image/png'
  }
  // GIF
  if (buffer[0] === 0x47 && buffer[1] === 0x49 && buffer[2] === 0x46) {
    return 'image/gif'
  }
  // WebP
  if (buffer[8] === 0x57 && buffer[9] === 0x45 && buffer[10] === 0x42 && buffer[11] === 0x50) {
    return 'image/webp'
  }
  return null
}

const removeSelectedImage = () => {
  selectedImage.value = null
  if (messageInput.value) {
    messageInput.value.value = ''
  }
}

// 生命周期钩子

onMounted(() => {
  getFriendInfo()
  messagesInit()
  mainContainer.value.addEventListener('scroll', handleScroll)
})

</script>