import { ref } from 'vue'
import zhCN from '../locales/zh-CN'
import enUS from '../locales/en-US'

const messages = {
    'zh-CN': zhCN,
    'en-US': enUS
}

const currentLang = ref(localStorage.getItem('language') || 'zh-CN')

export function useLanguage() {
    const t = (key) => {
        const keys = key.split('.')
        let result = messages[currentLang.value]

        keys.forEach(k => {
            result = result[k]
        })

        return result
    }

    const changeLang = (lang) => {
        currentLang.value = lang
        localStorage.setItem('language', lang)
    }

    return {
        t,
        currentLang,
        changeLang
    }
}