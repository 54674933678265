<template>
    <div class="fixed inset-0 bg-black/30 backdrop-blur-sm z-[60] flex items-center justify-center"
      @click.self="$emit('close')">
      <div
        class="bg-amber-50/95 rounded-2xl shadow-xl max-w-md w-[90%] transform transition duration-200 border border-amber-100">
        <!-- 弹窗标题栏 -->
        <div class="p-3 border-b border-amber-100 flex justify-between items-center">
          <div class="flex items-center space-x-3">
            <PencilIcon class="h-5 w-5 text-amber-500" />
            <span class="text-lg font-medium text-amber-700">
              {{ isEditing ? '编辑记忆' : '添加新记忆' }}
            </span>
          </div>
          <button @click="$emit('close')" 
            class="p-1.5 hover:bg-amber-100/50 rounded-full transition-colors">
            <XMarkIcon class="h-5 w-5 text-amber-600" />
          </button>
        </div>
  
        <!-- 弹窗内容区 -->
        <div class="p-3">
          <textarea 
            v-model="localContent"
            class="w-full bg-white/60 rounded-lg p-4 min-h-[120px] max-h-[300px] resize-y
                   border border-amber-100/50 shadow-sm
                   hover:bg-white/80 transition-colors
                   placeholder:text-amber-400/80
                   text-amber-900
                   focus:outline-none focus:ring-2 focus:ring-amber-400"
            :placeholder="`记录关于${friendInfo.name}的记忆...`"
            :disabled="saving"
          ></textarea>
  
          <button @click="handleSave"
            class="w-full mt-2 px-4 py-2 rounded-lg
                   bg-amber-400/90 hover:bg-amber-500/90 
                   text-amber-50 font-medium
                   transition-colors shadow-sm
                   disabled:opacity-50 disabled:cursor-not-allowed
                   flex items-center justify-center"
            :disabled="!localContent.trim() || saving">
            <span v-if="saving" class="flex items-center">
              <svg class="animate-spin -ml-1 mr-2 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
              保存中...
            </span>
            <span v-else>保存</span>
          </button>
        </div>
      </div>
    </div>
  </template>
  
  <script setup>
  import { ref, computed, onMounted } from 'vue'
  import { PencilIcon, XMarkIcon } from '@heroicons/vue/24/outline'
  
  const props = defineProps({
    friendInfo: {
      type: Object,
      required: true
    },
    memory: {
      type: Object,
      default: null
    }
  })
  
  const emit = defineEmits(['close', 'refresh'])
  
  // 本地状态
  const localContent = ref('')
  const saving = ref(false)
  
  // 是否为编辑模式
  const isEditing = computed(() => !!props.memory)
  
  // 初始化编辑内容
  onMounted(() => {
    if (props.memory) {
      localContent.value = props.memory.content
    }
  })
  
  // 保存处理
  const handleSave = async () => {
    if (!localContent.value.trim() || saving.value) return
  
    saving.value = true
    const userId = localStorage.getItem('user_id')
    const friendId = props.friendInfo.friend_id
  
    try {
      const endpoint = isEditing.value ? '/api/memories/update' : '/api/memories/add'
      const body = {
        user_id: userId,
        friend: friendId,
        content: localContent.value.trim()
      }
  
      // 如果是编辑模式，添加记忆ID
      if (isEditing.value && props.memory) {
        body.memory_id = props.memory.id
      }
  
      const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body)
      })
  
      if (!response.ok) {
        throw new Error('保存失败')
      }
  
      // 成功后关闭弹窗并刷新列表
      emit('refresh')
      emit('close')
  
    } catch (error) {
      console.error('Error saving memory:', error)
      alert(isEditing.value ? '更新记忆失败，请重试' : '添加记忆失败，请重试')
    } finally {
      saving.value = false
    }
  }
  </script>