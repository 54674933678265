<template>
    <div class="fixed inset-0 bg-black/30 backdrop-blur-sm z-50 flex items-center justify-center"
      @click.self="$emit('close')">
      <div class="bg-amber-50/95 rounded-2xl shadow-xl max-w-md w-[90%] max-h-[70vh] flex flex-col transform scale-100 duration-200 border border-amber-100">
        <!-- 浮层标题和添加按钮 -->
        <div class="p-4 border-b border-amber-100 flex justify-between items-center">
          <div class="flex items-center space-x-3">
            <LightBulbIcon class="h-5 w-5 text-amber-500" />
            <span class="text-lg font-medium text-amber-700">记忆</span>
            <span class="text-sm text-amber-500/80">{{ memories.length }}/50</span>
          </div>
          <div class="flex items-center space-x-2">
            <!-- 添加记忆按钮 -->
            <button 
              @click="handleAdd"
              class="p-1.5 hover:bg-amber-100/50 rounded-full transition-colors"
              :disabled="memories.length >= 50">
              <PlusIcon class="h-5 w-5 text-amber-600" />
            </button>
            <!-- 关闭按钮 -->
            <button 
              @click="$emit('close')"
              class="p-1.5 hover:bg-amber-100/50 rounded-full transition-colors">
              <XMarkIcon class="h-5 w-5 text-amber-600" />
            </button>
          </div>
        </div>
  
        <!-- 记忆列表 -->
        <div class="flex-1 overflow-y-auto custom-scrollbar px-4">
          <div class="space-y-3 py-4">
            <div 
              v-for="memory in sortedMemories" 
              :key="memory.id"
              class="bg-white/60 rounded-lg p-4 hover:bg-white/80 transition-colors border border-amber-100/50 shadow-sm group">
              <div class="flex items-start">
                <div class="flex-shrink-0 mr-3 mt-1">
                  <div class="w-2 h-2 rounded-full bg-amber-400"></div>
                </div>
                <div class="flex-1">
                  <div class="flex justify-between items-start">
                    <p class="text-amber-900 text-sm break-words leading-relaxed flex-1">
                      {{ memory.content }}
                    </p>
                    <div class="flex space-x-1 opacity-0 group-hover:opacity-100 transition-opacity ml-2">
                      <!-- 编辑按钮 -->
                      <button 
                        @click="handleEdit(memory)"
                        class="p-1 hover:bg-amber-100 rounded-full">
                        <PencilIcon class="h-4 w-4 text-amber-600" />
                      </button>
                      <!-- 删除按钮 -->
                      <button 
                        @click="handleDelete(memory.id)"
                        class="p-1 hover:bg-amber-100 rounded-full">
                        <TrashIcon class="h-4 w-4 text-amber-600" />
                      </button>
                    </div>
                  </div>
                  <p class="text-xs text-amber-500/80 mt-2 italic">{{ formatDate(memory.date) }}</p>
                </div>
              </div>
            </div>
  
            <!-- 空状态 -->
            <div v-if="memories.length === 0" class="text-center py-8 text-amber-500">
              还没有记忆，点击上方的"+"添加第一条记忆
            </div>
          </div>
        </div>
      </div>
  
      <!-- 添加/编辑记忆模态框 -->
      <MemoryEditModal
        v-if="showMemoryModal"
        :memory="editingMemory"
        :friend-info="friendInfo"
        v-model="memoryContent"
        @close="closeMemoryModal"
        @refresh="loadMemories"
      />
    </div>
  </template>
  
  <script setup>
  import { ref, computed, onMounted } from 'vue'
  import { 
    LightBulbIcon, 
    PlusIcon, 
    XMarkIcon,
    PencilIcon,
    TrashIcon 
  } from '@heroicons/vue/24/outline'
  import MemoryEditModal from './MemoryEditModal.vue'
  
  const props = defineProps({
    friendInfo: {
      type: Object,
      required: true
    }
  })
  
  // 记忆相关状态
  const memories = ref([])
  const showMemoryModal = ref(false)
  const memoryContent = ref('')
  const editingMemory = ref(null)
  
  // 加载记忆列表
  const loadMemories = async () => {
    const userId = localStorage.getItem('user_id')
    const friendId = props.friendInfo.friend_id
  
    try {
      const response = await fetch(`/api/memories/get?user_id=${userId}&friend=${friendId}`)
      if (response.ok) {
        const memoriesData = await response.json()
        memories.value = Array.isArray(memoriesData) ? memoriesData : []
      }
    } catch (error) {
      console.error('Error fetching memories:', error)
    }
  }
  
  // 按日期排序的记忆列表
  const sortedMemories = computed(() => {
    return [...memories.value].sort((a, b) => new Date(b.date) - new Date(a.date))
  })
  
  // 处理添加记忆
  const handleAdd = () => {
    editingMemory.value = null
    memoryContent.value = ''
    showMemoryModal.value = true
  }
  
  // 处理编辑记忆
  const handleEdit = (memory) => {
    editingMemory.value = memory
    memoryContent.value = memory.content
    showMemoryModal.value = true
  }
  
  // 关闭记忆编辑模态框
  const closeMemoryModal = () => {
    showMemoryModal.value = false
    memoryContent.value = ''
    editingMemory.value = null
  }
  
  // 处理删除记忆
  const handleDelete = async (memoryId) => {
    if (!confirm('确定要删除这条记忆吗？')) return
  
    const userId = localStorage.getItem('user_id')
    const friendId = props.friendInfo.friend_id
  
    try {
      const response = await fetch('/api/memories/delete', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_id: userId,
          friend: friendId,
          memory_id: memoryId
        }),
      })
  
      if (!response.ok) {
        throw new Error('删除失败')
      }
  
      // 删除成功后重新加载记忆列表
      await loadMemories()
    } catch (error) {
      console.error('Error deleting memory:', error)
      alert('删除记忆失败，请重试')
    }
  }
  
  // 格式化日期
  const formatDate = (dateString) => {
    const date = new Date(dateString)
    const now = new Date()
    const diff = now - date
    const days = Math.floor(diff / (1000 * 60 * 60 * 24))
  
    if (days === 0) return '今天'
    if (days === 1) return '昨天'
    if (days < 7) return `${days}天前`
    
    return date.toLocaleDateString('zh-CN', {
      month: 'long',
      day: 'numeric'
    })
  }
  
  // 组件挂载时加载记忆列表
  onMounted(() => {
    loadMemories()
  })
  </script>